'use strict';

import $ from 'jquery'

let defaultOptions = {
    offset: 0,
    setInitialPosition: false
};
let defaultSelectors = {
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    defaultOptions = {
        ...defaultOptions,
        ...options
    };

    defaultSelectors = {
        ...defaultSelectors,
        ...selectors
    };

    if (defaultOptions.setInitialPosition){
        setTimeout(function() {
            setInitialPosition($('body'), options, selectors);
        }, 1);
    }

}

export function initInScope($scope = $('body')) {
    const $anchor = $scope.find(defaultSelectors.link);
    $anchor.on('click', function(evt) {
        evt.preventDefault();
        const
            $anchor = $(this),
            $target = $(this.hash);

        let offset = $anchor.data(defaultSelectors.dataOffset) ? $anchor.data(defaultSelectors.dataOffset) : defaultOptions.offset;

        smoothscroll(this, $target, offset, defaultSelectors, $(this).offset().top)
    });
}

export function setOffset(offset) {
    defaultOptions.offset = offset;
}

export function smoothscroll(el, $target, offset = defaultOptions.offset, selectors, anchorOffset) {

    const $tabPaneParent = $target.closest('.tab-pane:not(.active)');
    const $collapseParent = $target.closest('.collapse');

    if($target.data('toggle') === 'tab'){
        scrollTo(el, $target, offset, anchorOffset);

        $target.tab('show');
        $target.one('shown.bs.tab', function () {
            scrollTo(el, $target, offset, anchorOffset);
        });

    } else if ($tabPaneParent && $tabPaneParent.length) {
        /* anchor in tab */
        const $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');

        $tabTrigger.tab('show');
        $tabTrigger.one('shown.bs.tab', function () {
            scrollTo(el, $target, offset, anchorOffset)
        });

    } else if($target.data('toggle') === 'collapse'){
        if($target.hasClass('collapsed')){
            const collapseTrigger = $($target.data('target'));
            collapseTrigger.collapse('show');
            collapseTrigger.one('shown.bs.collapse', function () {
                scrollTo(el, $target, offset, anchorOffset)
            });
        }else{
            scrollTo(el, $target, offset, anchorOffset)
        }

    } else if ($collapseParent && $collapseParent.length) {
        /* anchor in accordion */

        if(!$collapseParent.hasClass('show')){
            $collapseParent.collapse('show');
            $collapseParent.one('shown.bs.collapse', function () {
                scrollTo(el, $target, offset, anchorOffset)
            });
        }else{
            scrollTo(el, $target, offset, anchorOffset)
        }

    } else {
        // no anchor in hidden tab or accordion
        scrollTo(el, $target, offset, anchorOffset)
    }

}

function scrollTo(el, $target, offset, anchorOffset) {
    let time = $target.offset().top - anchorOffset > 0 ? ($target.offset().top - anchorOffset)/3 : (($target.offset().top - anchorOffset)*-1)/3;
    console.log(time);

    $('html, body').stop().animate({
        scrollTop: $target.offset().top - offset
    }, time, 'swing', function() {

        $target.focus();
        if(el){
            history.replaceState({}, '', el.hash);
        }
        if ($target.is(":focus")) {
            return false;
        } else {
            $target.focus();
        }
    });
}

function setInitialPosition($scope, options, selectors) {
    if(window.location.hash) {
        const
            pattern = new RegExp(window.location.hash),
            $anchors = $scope.find(selectors.link),
            $target = $(window.location.hash);

        let offset = options.offset;

        $anchors.each((i,_el) => {
            offset = pattern.test($(_el).attr('href')) && $(_el).data(selectors.dataOffset) ? $(_el).data(selectors.dataOffset) : offset;
        });

        smoothscroll(this, $target, offset, defaultSelectors, 0)
    }
}