import throwError from '@elements/throw-error';

export function getConfig(configName = '_config') {
    if (!window[configName]) {
        throwError(`Global var ${configName} was not set.`);
    }

    return window[configName];
}

export function getConfigValue(param, configName = '_config') {
    if (typeof param !== 'object'){
        param = {
            name: param,
            optional: false,
            validator: null
        }
    }

    let config = getConfig(configName);
    if (!config) {
        return;
    }

    let configValue = config[param.name];
    if (configValue) {
        if (param.validator) {
            if (param.validator(configValue)) {
                return configValue;
            } else {
                throwError(
                    param.validator.message || `Value of config "${param.name}" did not match the validator "${param.validator.name}". Value was` ,
                    configValue
                );
            }
        } else {
            return configValue;
        }
    } else if (!param.optional) {
        throwError(`Missing config "${param.name}". Value of "${param.name}" was not set in "${configName}"`, config);
    }
}

export function requireConfigValues (configs, configName = '_config') {
    return configs.map(configValue => getConfigValue(configValue, configName));
}