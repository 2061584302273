"use strict";

import $ from 'jquery';

let promise;

export default function () {
    if (promise) {
        return promise;
    }

    promise = $.Deferred();

    // because jquery-ui-datepicker-with-i18n requires these global vars
    window.$ = window.$ || $;
    window.jQuery = window.jQuery || $;

    window.$.fn.zIndex = function() {
        /* backfill old zIndex function for old jquery ui datepicker*/
        return this.css("zIndex");
    };

    import("@elements/jquery-ui-datepicker-with-i18n/ui/jquery.ui.datepicker.js").then(function () {
        let lang = _config.local || _config.lang;
        if (lang && lang !== 'en') {
            import('@elements/jquery-ui-datepicker-with-i18n/ui/i18n/jquery.ui.datepicker-' + lang + '.js').then(function () {
                promise.resolve();
            }).catch(function () {
                if (lang !== _config.lang) {
                    /* if the _config.local was used and there is no matching package try to load the package for _config.lang */
                    return import('@elements/jquery-ui-datepicker-with-i18n/ui/i18n/jquery.ui.datepicker-' + _config.lang + '.js').then(promise.resolve)
                } else {
                    /*fallback if there is no package*/
                    promise.resolve();
                }
            }).catch(function () {
                /*fallback if there is no package*/
                promise.resolve();
            });
        } else {
            promise.resolve();
        }
    }).catch(function (error) {
        promise.reject();
        console.error('Could not load @elements/jquery-ui-datepicker-with-i18n/ui/jquery.ui.datepicker.js', error);
    });

    return promise;
};