import {dateToISOString, localDateToUTCDate, ISOStringToDate, UTCDateToLocalDate} from '@elements/date-utils';
import $ from 'jquery';
import loadJQueryUi from '@elements/load-jquery-ui';
import throwError from '@elements/throw-error';
import {getPrefixedDataSet} from "@elements/data-set-utils";

const defaultSelectors = {
    base: '.js-date-input',
    input: '.js-date-input__input',
    output: '.js-date-input__output',
    openDatepicker: '.js-date-input__open-datepicker',
};

const defaultOptions = {
    placeholderDate: '1970-10-20T00:00:00',
    changeMonth: true,
    changeYear: true,
    yearRange: "c-80:c+80",
    numberOfMonths: 1,
    nextText: '<span class="icon icon-arrow"></span>',
    prevText: '<span class="icon icon-arrow"></span>',
    firstDay: 1,
    showAnim: 'show' // other animations (like fadeIn) do not work with jquery.slim
};

export function createInitInScope(options = {}, selectors = {}) {
    options = {
        ...defaultOptions,
        ...options,
    };
    selectors = {
        ...defaultSelectors,
        ...selectors,
    };

    return function ($scope) {
        let $elements = $scope.find(selectors.base);

        loadJQueryUi().then(() => {
            let local = (_config.local &&  $.datepicker.regional[_config.local])
                ? _config.local
                : (_config.lang || '');

            let datepickerLocalData = $.datepicker.regional[local];
            if (!datepickerLocalData) {
                datepickerLocalData = $.datepicker.regional[''];
            }

            const dateFormat = datepickerLocalData.dateFormat;

            $elements.each(function () {
                let $element = $(this);
                let elementOptions = {
                    onSelect: function (dateString, inst) {
                        let selectedDate = new Date(Date.UTC(inst.selectedYear, inst.selectedMonth, inst.selectedDay));
                        setOutputValue(selectedDate);
                        $input.trigger('input').trigger('change');
                    },
                    showOn: null,
                    ...options,
                    ...transformDataOptions(getPrefixedDataSet('date-input', $element))
                };

                let $input = $element.find(selectors.input);
                let $output = $element.find(selectors.output);
                let $openDatepicker = $element.find(selectors.openDatepicker);

                // If another datepicker (loaded by the project) is already available, the datepicker div must be reused
                if ($('#ui-datepicker-div').length) {
                    $.datepicker.dpDiv = $('#ui-datepicker-div');
                }

                $input.datepicker(elementOptions);

                $input.on('change', (evt) => {
                    try {
                        let date = $.datepicker.parseDate(dateFormat, evt.target.value);
                        $input.datepicker('setDate', date);
                        date = $input.datepicker('getDate')
                        setOutputValue(localDateToUTCDate(date));
                    } catch (e) {
                    }
                });

                $openDatepicker.on('click', function () {
                    $input.datepicker('show');
                });

                //set initial values & placeholder
                if ($output.val()) {
                    let date = ISOStringToDate($output.val());
                    if (date instanceof Date && !isNaN(date.getMonth())) { // check if valid date
                        $input.datepicker('setDate', UTCDateToLocalDate(date));
                    } else {
                        throwError(`${$output.val()} is not a valid date. Please use the following format: 1970-01-01T00:00:00`, $output);
                    }
                }

                $input.attr('placeholder', $.datepicker.formatDate(dateFormat, ISOStringToDate(elementOptions.placeholderDate)));
                $input.attr('pattern', createPatternForFormat(dateFormat));

                function setOutputValue(date) {
                    $output.val(dateToISOString(date)).trigger('change');
                }
            });
        });
    }

}

export const initInScope = createInitInScope();

function transformDataOptions(options) {
    options = {...options};

    if (options.minDate && typeof options.minDate === 'string') {
        options.minDate = UTCDateToLocalDate(ISOStringToDate(options.minDate));
    }

    if (options.maxDate && typeof options.maxDate === 'string') {
        options.maxDate = UTCDateToLocalDate(ISOStringToDate(options.maxDate));
    }

    return options;
}

function createPatternForFormat(format) {
    return format
        .replace(/\./gi, '\\.')
        .replace(/\//gi, '\\/')
        .replace(/(d+)/gi, '[0123]?\\d')
        .replace(/(m+)/gi, '[01]?\\d')
        .replace(/(y+)/gi, '\\d{4}');
}
